

















































































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";
import uploadNew from "@/components/uploadNew.vue";
import {clientLink, showTagRE} from '@/utils/common'

@Component({
    name: "activityAdd",
    components: {
        uploadNew: uploadNew
    }
})

export default class extends Vue {

    // 可用地区
    showIconChannel = true
    channelList: any = []
    channelListC: any = []
    channelDialog = false
    channelData: any = []
    search2: any = {
        name: '',
        bond_type: '',
        project_type: '',
        // pagination: {
        //   page: 0, //页码，从0开始
        //   pageSize: 100, //每页数量
        // },
    };
    total = 0
    getChannel() {
        this.channelData = []
        api
            .post('A23006', this.search2)
            .then(res => {
                this.channelDialog = true
                this.channelData = res.list
                this.channelData.unshift({
                    code: '0000',
                    channel_id: '0000',
                    name: '全部地区'
                })
                console.log('全部地区', this.channelData);
            })
    }

    changePage2(page: number) {
        this.search2.pagination.page = page - 1;
        this.getChannel()
    }

    closeChannelDialog() {
        this.channelDialog = false
    }
    handleChannelChange(rows: any){
        let selectedData: any = []
        if (rows) {
            rows.forEach((row: any) => {
                if (row) {
                    selectedData.push(row);
                }
            });
        }
        this.channelList = selectedData
    }
    sureChannel() {
        if(this.channelList.length != 0) {
            this.showIconChannel = false
            this.channelListC = [...this.channelList]
        }
        this.channelDialog = false
    }

    delChannelTag(row: any) {
        this.channelListC.splice(this.channelListC.findIndex((item: any) => item.channel_id === row.channel_id), 1)

        // this.channelListC = [...this.channelList]
        if(this.channelListC.length == 0) {
            this.showIconChannel = true
        }
    }
    delChannelAll() {
        this.channelListC = [];
        this.showIconChannel = true;
        (this.$refs as any).table2.clearSelection();

    }
    editChannelList() {
        this.channelList = []
        this.channelList = [...this.channelListC]
        this.channelDialog = true
    }

    openC() {
        this.channelData = [];
        api.post('A23006', this.search2)
            .then(res => {
                this.channelData = res.list
                this.channelData.unshift({
                    code: '0000',
                    channel_id: '0000',
                    name: '全部地区'
                })
                console.log('ccccc', this.channelListC);
                let selected = this.channelListC;//默认要选择复选框的数据
                // (this.$refs as any).table2.clearSelection();
                (this.$refs.table2 as any).clearSelection();
                selected.forEach((item:any) => { // checkedData为已选数据
                    this.$nextTick( ()=>{
                        this.channelData.find((obj: any) => { // userData 表单数据
                            if(item.channel_id === obj.channel_id) {
                                (this.$refs.table2 as any).toggleRowSelection(obj,true);
                                // this.$forceUpdate()
                            }
                        })

                    })
                })
            })
    }
    getRowKey(row: any) {
        return row.code
    }

    // 可用机构
    showIconOrg = true
    orgList: any = []
    orgListC: any = []
    orgDialog = false
    orgData: any = []
    keyword = ''
    getOrg() {
        this.orgData = []
        api
            .post('A23005', {keyword: this.keyword})
            .then(res => {
                this.orgDialog = true
                this.orgData = res.list
                this.orgData.unshift({
                    code: '0000',
                    name: '全部地区',
                    alias: '全部地区'
                })
                console.log('222', this.orgData);
            })
    }

    handleOrgChange(val: any) {
        this.orgList = val
    }

    sureOrg() {
        if(this.orgList.length != 0) {
            this.showIconOrg = false
            this.orgListC = [...this.orgList]
        }
        this.orgDialog = false
    }

    closeOrgDialog() {
        this.orgDialog = false
    }

    delTag(row: any) {
        this.orgListC.splice(this.orgListC.findIndex((item: any) => item.code === row.code), 1)
        // this.orgListC = [...this.orgList]
        if(this.orgListC.length == 0) {
            this.showIconOrg = true
        }
    }
    delAll() {
        // this.orgList = []
        this.orgListC = []
        this.showIconOrg = true;
        (this.$refs.table as any).clearSelection();
    }
    editOrgList() {
        this.orgList = [...this.orgListC]
        this.orgDialog = true
    }
    open() {
        this.channelData = [];
        api.post('A23005', {keyword: this.keyword})
            .then(res => {
                this.orgData = res.list
                this.orgData.unshift({
                    code: '0000',
                    alias: '全部地区',
                    name: '全部地区'
                })

                let selected = this.orgListC;//默认要选择复选框的数据
                // (this.$refs as any).table2.clearSelection();
                (this.$refs.table as any).clearSelection();
                selected.forEach((item:any) => { // checkedData为已选数据
                    this.$nextTick( ()=>{
                        this.orgData.find((obj: any) => { // userData 表单数据
                            if(item.code === obj.code) {
                                (this.$refs.table as any).toggleRowSelection(obj,true);
                                // this.$forceUpdate()
                            }
                        })

                    })
                })
            })



        // console.log('this.orgList', this.orgListC);
        // let selected = this.orgListC//默认要选择复选框的数据
        // this.orgDialog = true;
        // // (this.$refs.table as any).clearSelection();
        // selected.forEach((i: any) => {
        //     this.$nextTick(() => {
        //         (this.$refs.table as any).toggleRowSelection(
        //             this.orgData.find(
        //                 (d: any) => d.code == i.code
        //             ),
        //             true
        //         )  // 设置默认选中
        //     })
        // })
    }


    guizeList = [
        {loi: '', rci: '', prizePool: ''}
    ]

    addJC() {
        this.guizeList.push({
            loi: '', rci: '', prizePool: ''
        })
    }

    delJC(index: any) {
        this.guizeList.splice(index, 1)
    }

    form: any = {

    }

    // 标签
    tagVal = ''
    tags: any = []
    tagsC: any = []
    newTags: any = []
    delTags: any = []


    handleClose(tag: any) {
        this.delTags.push(tag)
        this.tags.splice(this.tags.indexOf(tag), 1);

    }


    sureLoading = false
    sure() {
        if(!this.form.lotteryName){
            this.$message.warning('请填写活动名称')
            return
        }

        let a: any = []
        this.orgListC.forEach((item: any) => {
            a.push(item.code)
        })
        this.form.sendingOrganizationCode = a.join(',')

        let b: any = []
        this.channelListC.forEach((item: any) => {
            b.push(item.channel_id)
        })
        this.form.tradingCenterCode = b.join(',')

        console.log(this.form);

        this.form.lotteryRuleInfos = this.guizeList


        this.sureLoading = true
        api
            .post(this.id ? 'J27705' : 'J27702', this.form)
            .then(res =>{
                this.tags = []
                this.newTags = []
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                // if(this.$route.query.id) {
                //     this.getDetail(this.$route.query.id)
                // }
                this.$router.push({
                    name: 'applet_activity_prize'
                })
            }).finally(() => {
                this.sureLoading = false
        })

    }

    cancle() {}


    // 编辑
    getDetail(id: any) {
        api
            .post('J27704', {id: this.id})
            .then(async (res: any) => {
                this.form = res

                this.guizeList = this.form.lotteryRuleInfos

                if(this.form.sendingOrganizationCode) {
                    let a: any = []
                    let tradingCenterCode = this.form.tradingCenterCode.split(',')
                    console.log('tradingCenterCode', tradingCenterCode);
                    api
                        .post('A23006', this.search2)
                        .then(res => {
                            this.channelData = res.list
                            this.channelData.unshift({
                                code: '0000',
                                name: '全部地区',
                                channel_id:'0000'
                            })
                            console.log('全部地区', this.channelData);
                            this.channelData.forEach((row: any) => {

                                tradingCenterCode.forEach((item: any) => {
                                    if(row.channel_id == item) {
                                        a.push(row)
                                    }
                                })
                            })
                            this.channelListC = a
                            // this.channelList = a
                            this.showIconChannel = false
                        })
                }

                if(this.form.sendingOrganizationCode) {
                    let a: any = []
                    let sendingOrganizationCode = this.form.sendingOrganizationCode.split(',')
                    console.log('sendingOrganizationCode', sendingOrganizationCode);
                    api
                        .post('A23005', this.search2)
                        .then(res => {
                            this.orgData = res.list
                            this.orgData.unshift({
                                code: '0000',
                                name: '全部地区',
                                alias: '全部地区'
                            })
                            this.orgData.forEach((row: any) => {

                                sendingOrganizationCode.forEach((item: any) => {
                                    if(row.code == item) {
                                        a.push(row)
                                    }
                                })
                            })
                            this.orgListC = a
                            this.showIconOrg = false
                        })
                }



            })
    }

    id: any = ''
    showUpload = true
    activated() {
        this.form = {}
        this.channelListC = []
        this.guizeList = [
            {
                loi: '', rci: '', prizePool: ''
            }
        ]
        this.showIconChannel = true
        this.showIconOrg = true
        this.orgListC = []
        this.id = this.$route.query.id
        if(this.id) {
            this.getDetail(this.id)

        }
        this.showUpload = false
        this.$nextTick(() => {this.showUpload = true})

    }


}
